var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"review"},[_c('div',{staticClass:"text-center mt-5"},[(_vm.loading)?_c('b-spinner'):_vm._e()],1),_c('b-row',{staticClass:"p-lg-5 p-0 pt-0 mt-6 ml-lg-5 mr-lg-5 mb-6"},_vm._l((_vm.Categoryname),function(item,index){return _c('b-col',{key:index,attrs:{"cols":"12","sm":_vm.$route.params.name ? 6 : 4}},[(
            _vm.$route.params.name &&
            _vm.$route.params.name
              .toLowerCase()
              .split('-')
              .map(function (word) { return word.charAt(0).toUpperCase() + word.slice(1); })
              .join(' ') ==
              index
                .toLowerCase()
                .split(' ')
                .map(function (word) { return word.charAt(0).toUpperCase() + word.slice(1); })
                .join(' ')
          )?_c('b-card',{staticClass:"text-center lightColor border-2-color pt-4 pb-4",class:_vm.hide.includes(index) != 0 ? 'h-100' : ''},[_c('b-card-header',{staticClass:"border-0 bg-none p-0"},[_c('strong',{staticClass:"text-capitalize"},[_vm._v(_vm._s(index))])]),_c('b-card-text',{staticClass:"mt-5"},_vm._l((item),function(i,keys){return _c('div',{key:keys},[_c('div',{staticClass:"pt-3"},[_c('router-link',{staticClass:"findcompany",attrs:{"to":'/listcompany/' + i.namelink}},[_vm._v(_vm._s(i.name)),_c('b-icon',{staticClass:"font-bold",attrs:{"icon":"chevron-right","scale":"1"}})],1)],1)])}),0)],1):_vm._e(),(!_vm.$route.params.name)?_c('b-card',{staticClass:"text-center lightColor border-2-color pt-4 pb-4",class:_vm.hide.includes(index) != 0 ? 'h-100' : ''},[_c('b-card-header',{staticClass:"border-0 bg-none p-0"},[_c('strong',{staticClass:"text-capitalize"},[_vm._v(_vm._s(index))])]),_c('b-card-text',{staticClass:"mt-5"},_vm._l((item),function(i,keys){return _c('div',{key:keys},[(keys < 5)?_c('div',{staticClass:"pt-3"},[_c('router-link',{staticClass:"findcompany",attrs:{"to":'/listcompany/' + i.namelink}},[_vm._v(_vm._s(i.name)),_c('b-icon',{staticClass:"font-bold",attrs:{"icon":"chevron-right","scale":"1"}})],1)],1):_vm._e(),(keys > 6 && _vm.shows == index)?_c('div',{staticClass:"pt-3"},[_c('router-link',{staticClass:"findcompany",attrs:{"to":'/listcompany/' + i.namelink}},[_vm._v(_vm._s(i.name)),_c('b-icon',{staticClass:"font-bold",attrs:{"icon":"chevron-right","scale":"1"}})],1)],1):_vm._e(),(keys == 6 && _vm.hide.indexOf(index) == -1)?_c('div',{staticClass:"pt-5"},[_c('v-btn',{attrs:{"depressed":"","elevation":"6","icon":"","outlined":"","raised":"","color":"black"},on:{"click":function($event){return _vm.getshow(index)}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1):_vm._e(),(item.length <= 5 && keys == 4)?_c('div',{staticClass:"pt-5"},[_c('button')]):_vm._e()])}),0)],1):_vm._e()],1)}),1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }