<template>
  <div>
    <section class="review">
      <div class="text-center mt-5">
        <b-spinner v-if="loading"></b-spinner>
      </div>
      <b-row class="p-lg-5 p-0 pt-0 mt-6 ml-lg-5 mr-lg-5 mb-6">
        <b-col
          cols="12"
          :sm="$route.params.name ? 6 : 4"
          v-for="(item, index) in Categoryname"
          :key="index"
        >
          <b-card
            class="text-center lightColor border-2-color pt-4 pb-4"
            :class="hide.includes(index) != 0 ? 'h-100' : ''"
            v-if="
              $route.params.name &&
              $route.params.name
                .toLowerCase()
                .split('-')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ') ==
                index
                  .toLowerCase()
                  .split(' ')
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(' ')
            "
          >
            <b-card-header class="border-0 bg-none p-0">
              <strong class="text-capitalize">{{ index }}</strong>
            </b-card-header>
            <b-card-text class="mt-5">
              <div v-for="(i, keys) in item" :key="keys">
                <div class="pt-3">
                  <router-link
                    :to="'/listcompany/' + i.namelink"
                    class="findcompany"
                    >{{ i.name
                    }}<b-icon
                      class="font-bold"
                      icon="chevron-right"
                      scale="1"
                    ></b-icon
                  ></router-link>
                </div>
              </div>
            </b-card-text>
          </b-card>
          <b-card
            class="text-center lightColor border-2-color pt-4 pb-4"
            :class="hide.includes(index) != 0 ? 'h-100' : ''"
            v-if="!$route.params.name"
          >
            <b-card-header class="border-0 bg-none p-0">
              <strong class="text-capitalize">{{ index }}</strong>
            </b-card-header>
            <b-card-text class="mt-5">
              <div v-for="(i, keys) in item" :key="keys">
                <div v-if="keys < 5" class="pt-3">
                  <router-link
                    :to="'/listcompany/' + i.namelink"
                    class="findcompany"
                    >{{ i.name
                    }}<b-icon
                      class="font-bold"
                      icon="chevron-right"
                      scale="1"
                    ></b-icon
                  ></router-link>
                </div>
                <div v-if="keys > 6 && shows == index" class="pt-3">
                  <router-link
                    :to="'/listcompany/' + i.namelink"
                    class="findcompany"
                    >{{ i.name
                    }}<b-icon
                      class="font-bold"
                      icon="chevron-right"
                      scale="1"
                    ></b-icon
                  ></router-link>
                </div>
                <div v-if="keys == 6 && hide.indexOf(index) == -1" class="pt-5">
                  <!-- <button  class="txt-brown font-bold"> -->
                  <v-btn
                    depressed
                    elevation="6"
                    icon
                    outlined
                    raised
                    @click="getshow(index)"
                    color="black"
                  >
                    <v-icon>mdi-plus</v-icon></v-btn
                  >
                  <!-- </button> -->
                </div>
                <div v-if="item.length <= 5 && keys == 4" class="pt-5">
                  <button></button>
                </div>
              </div>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "findcompany",
  data: function () {
    return {
      Categoryname: [],
      shows: null,
      hide: [],
      loading: false,
    };
  },
  created() {
    this.getCategory();
    this.loading = true;
  },
  methods: {
    ...mapActions(["getCompanyCategory"]),
    getCategory() {
      this.getCompanyCategory()
        .then((response) => {
          this.loading = true;

          let all_cat = {};

          response.data.data.forEach((value) => {
            let cat = [];
            if (value["parent_id"] == 0) {
              let s_id = value["id"];
              let s_name = value["name"];
              response.data.data.forEach((v) => {
                if (v["parent_id"] == s_id) {
                  let detail = {};
                  detail.name = v["name"];
                  detail.id = v["id"];
                  let nameconvert = v["name"]
                    .replaceAll(" ", "-")
                    .toLowerCase();
                  detail.namelink = nameconvert;
                  cat.push(detail);
                }
              });
              all_cat[s_name] = cat;
            }
          });

          this.Categoryname = all_cat;
        })
        .finally(() => (this.loading = false));
    },
    getshow(index) {
      this.hide = [];
      this.shows = index;
      this.hide.push(index);
    },
  },
};
</script>

